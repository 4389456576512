import { createSlice } from '@reduxjs/toolkit';

const initialState = ''

export const chatWidgetSlice = createSlice({
  name: 'chat_widget',
  initialState,
  reducers: {
    chatWidget: (state = initialState, {payload}) => {  
      console.log('s', state);
      return payload;
    },
  },
})

export const { chatWidget } = chatWidgetSlice.actions

export default chatWidgetSlice.reducer