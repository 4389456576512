import { createSlice } from '@reduxjs/toolkit';

const initialState = ''

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    user: (state = initialState, {payload}) => {  
      console.log('s', state);
      return payload;
    },
  },
})

export const { user } = userSlice.actions

export default userSlice.reducer