import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  meta: {
    length: 0,
    total: 0
  },
  data: []
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    products: (state = initialState, {payload}) => {
        console.log('s:', state)
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { products } = productsSlice.actions

export default productsSlice.reducer