import { createSlice } from '@reduxjs/toolkit';

const initialState = ''

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    shop: (state = initialState, {payload}) => {  
      console.log('s', state);
      return payload;
    },
  },
})

export const { shop } = shopSlice.actions

export default shopSlice.reducer