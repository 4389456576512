import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {chatWidget as chatWidgetReducer} from '../../redux/chatWidgetSlice';

export default function ChatWidget(){
  const dispatch = useDispatch();
  const chatWidget = useSelector((state)=> state.chatWidget);
  const user = useSelector((state)=> state.user);
 
  useEffect(() => {
    if(chatWidget){
      const { scriptUrl, widgetId, propertyId } = chatWidget;

      if (scriptUrl.includes('tawk')) {
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_LoadStart = new Date();
        loadScript(`${scriptUrl}${propertyId}/${widgetId}`, () => {
          // Set user details after the script loads
          if (user) {
            window.Tawk_API.visitor = {
              name: user.name,
              email: user.email,
              // hash: user.hash || '', // Optional: if you have a hash for verification
            };
          }
        });
      } else if (scriptUrl.includes('jivo')) {        
        loadScript(`${scriptUrl}${widgetId}`, () => {
          // Set user details after the script loads
          if (user) {
            if (window.jivo_api) {
              window.jivo_api.setContactInfo({
                name: user.name,
                email: user.email,
                phone: user.phone || '', // Optional
              });
            }
          }
        });
      }
    }else{
        loadChatWidgets();
    }

    return () => {
      if(chatWidget){
        // Cleanup: remove existing script tags if needed
        const scriptTags = document.querySelectorAll('script[src*="tawk.to"], script[src*="jivosite"]');
        scriptTags.forEach((script) => script.remove());
      }
    };
  },[chatWidget, user]);

  const loadChatWidgets = async () => {
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/chat-widget/public?isPrimary=true`);
      const { error, data } = res.data;

      if(!error && typeof data === 'object' && data.isPrimary){        
        dispatch(chatWidgetReducer(data));
      }
    }catch(err){
      console.error('e: ', err);
    }
  }

  const loadScript = (src) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
  };

  return ''
};
