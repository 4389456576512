import { createSlice } from '@reduxjs/toolkit';

const initialState = []

export const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {
    shops: (state = initialState, {payload}) => {  
      console.log('s', state);
      return payload;
    },
  },
})

export const { shops } = shopsSlice.actions

export default shopsSlice.reducer