import { createSlice } from '@reduxjs/toolkit';

const initialState = []

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    orders: (state = initialState, {payload}) => {  
      console.log('s', state);
      return payload;
    },
  },
})

export const { orders } = ordersSlice.actions

export default ordersSlice.reducer