import Intercom from '@intercom/messenger-js-sdk';
import { useSelector } from 'react-redux';
// import { user } from 'src/util/helper';

export default function IntercomeMessenger() { 
  const user = useSelector((state)=> state.user);
  const { id, name, email } = user;

  Intercom({
    app_id: 'yrwsw9zp',
    user_id: id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    // created_at: '2022-12-16 19:32:45.894136', // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });

  return '';
} 