import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// const CreateBankAccount = Loader(lazy(() => import('src/content/bankAccount/Create/')));
const BankAccounts = Loader(lazy(() => import('src/content/bankAccount/List/index')));

const shopRoutes = [
  {
    path: '/',
    element: <BankAccounts />
  },
  // {
  //   path: 'create',
  //   element: <CreateBankAccount />
  // },
];

export default shopRoutes;
