// import * as Icons from '@mui/icons-material';
// import { useSelector } from 'react-redux';
// import { getUrlSegment } from 'src/util/helper';
// import AllMenuItems from './TopNavigationLayout/TopBar/NavigationMenu/items';

// export const reloadMenus = () => {
//   window.location.assign(window.location.href);
// };

// const Menu = () => {
//   const user = useSelector((state) => state.user);
//   const firstUrlSegment = getUrlSegment(0);

//   // Helper function to create menu items
//   const createMenuItem = (name, icon, link, subItems = []) => ({
//     name,
//     icon: Icons[icon],
//     link,
//     items: subItems,
//   });

//   // Default menu items
//   const menuItems = [
//     {
//       heading: 'Setup',
//       items: [
//         createMenuItem('Bank Account', 'AccountTreeTwoToneIcon', `/${firstUrlSegment}/bank-account`),
//         createMenuItem('Product', 'AccountTreeTwoToneIcon', `/${firstUrlSegment}/product`),
//       ],
//     },
//     {
//       heading: 'Market',
//       items: [
//         createMenuItem('Order', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/order`),
//         createMenuItem('Payment', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/payment`),
//       ],
//     },
//     {
//       heading: 'Settings',
//       items: [
//         createMenuItem('Compliance', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/compliance`),
//         createMenuItem('Shop Settings', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/settings/shop`),
//         createMenuItem('Sliders', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/sliders`),
//       ],
//     },
//   ];

//   // Conditional menu items based on user permissions
//   if (user?.stringPermissions?.includes('manage-application') || user?.role === 'super') {
//     menuItems.push({
//       heading: 'System Management',
//       items: [
//         createMenuItem('System Setup', 'AssignmentIndTwoToneIcon', `/${firstUrlSegment}/management/system-setup`),
//         createMenuItem('Users', 'AssignmentIndTwoToneIcon', `/${firstUrlSegment}/management/users`),
//         createMenuItem('Roles', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/management/roles`),
//         createMenuItem('Permissions', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/management/permissions`),
//         createMenuItem('Banks', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/management/banks`),
//         createMenuItem('Currencies', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/management/currencies`),
//         createMenuItem('Product Categories', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/management/product-categories`),
//         createMenuItem('Product Sub Categories', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/management/product-sub-categories`),
//         createMenuItem('Countries', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/management/countries`),
//         createMenuItem('Mailbox', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/management/mailbox`),
//         createMenuItem('Chat Widget', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/management/chat-widget`),
//         createMenuItem('Shops', 'StorefrontTwoToneIcon', `/${firstUrlSegment}/management/shops`),
//       ],
//     });
//   }

//   // General menu items
//   const otherMenuItems = [
//     {
//       heading: 'General',
//       items: [
//         createMenuItem(
//           'Blueprints',
//           'BackupTableTwoToneIcon',
//           '',
//           [
//             createMenuItem('Extended Sidebar', '', `/${firstUrlSegment}/dashboards`, [], 'v3.0', 'Added in version 3.0'),
//             createMenuItem('Accent Header', '', '/accent-header/dashboards', [], 'Updated'),
//             createMenuItem('Boxed Sidebar', '', '/boxed-sidebar/dashboards'),
//           ]
//         ),
//         createMenuItem(
//           'Dashboards',
//           'SmartToyTwoToneIcon',
//           `/${firstUrlSegment}/dashboards`,
//           [
//             createMenuItem('Reports', '', 'dashboards/reports'),
//             createMenuItem('Expenses', '', 'dashboards/expenses'),
//             createMenuItem('Products', '', 'dashboards/products'),
//           ]
//         ),
//       ],
//     },
//   ];

//   const AllMenuItems = process.env.NODE_ENV === 'development' ? menuItems.concat(otherMenuItems) : menuItems;

//   return '';
// };

// export default AllMenuItems;

























import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import { getUrlSegment } from 'src/util/helper';
// import { store } from '../redux/store';
// import { useSelector } from 'react-redux';

let user = localStorage.getItem('user'); // later find a way to replace this with redux user
user = user && typeof user === 'string' ? JSON.parse(user) : user;
// const user = useSelector((state)=> state.user);
console.log('u555', user);
const firstUrlSegment = getUrlSegment(0);

export const reloadMenus = () => {
    window.location.assign(window.location.href);
}

const menuItems = [
  {
    heading: 'Setup',
    items: [
      {
        name: 'Bank Account',
        icon: AccountTreeTwoToneIcon,
        link: `/${firstUrlSegment}/bank-account`
      },
      {
        name: 'Product',
        icon: AccountTreeTwoToneIcon,
        link: `/${firstUrlSegment}/product`
      },
      // {
      //   name: 'Product Category',
      //   icon: ReceiptTwoToneIcon,
      //   link: `/${firstUrlSegment}/product/category`
      // },
      // {
      //   name: 'Shop',
      //   icon: StorefrontTwoToneIcon,
      //   link: `/${firstUrlSegment}/shop/active`
      // },
    ]
  },
  {
    heading: 'Market',
    items: [
      {
        name: 'Order',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/order`
      },
      {
        name: 'Payment',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/payment`
      },
    ]
  },
  {
    heading: 'Settings',
    items: [
      {
        name: 'Compliance',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/compliance`
      },
      {
        name: 'Shop Settings',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/settings/shop`
      },
      {
        name: 'Sliders',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/sliders`
      }
    ]
  },
  // {
  //   heading: 'Shop Management',
  //   items: [
  //     {
  //       name: 'Users',
  //       icon: AssignmentIndTwoToneIcon,
  //       link: `/${firstUrlSegment}/management/users'`
  //       items: [
  //         {
  //           name: 'List',
  //           link: 'management/shop/users'
  //         },
  //       ]
  //     }
  //   ]
  // },
];

if(user?.stringPermissions.includes('manage-application') || user?.role === 'super'){
  menuItems.push({
    heading: 'System Management',
    items: [
      {
        name: 'System Setup',
        icon: AssignmentIndTwoToneIcon,
        link: `/${firstUrlSegment}/management/system-setup`
      },
      {
        name: 'Users',
        icon: AssignmentIndTwoToneIcon,
        link: `/${firstUrlSegment}/management/users`
      },
      {
        name: 'Roles',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/management/roles`
      },
      {
        name: 'Permissions',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/management/permissions`
      },
      {
        name: 'Banks',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/management/banks`
      },
      {
        name: 'Currencies',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/management/currencies`
      },
      {
        name: 'Product Categories',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/management/product-categories`
      },
      {
        name: 'Product Sub Categories',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/management/product-sub-categories`
      },
      {
        name: 'Countries',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/management/countries`
      },
      {
        name: 'Mailbox',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/management/mailbox`
      },
      {
        name: 'Chat widget',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/management/chat-widget`
      },
      {
        name: 'Shops',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/management/shops`
      },
    ]
  })
}

const otherMenuItems = [
  {
    heading: 'General',
    items: [
      {
        name: 'Blueprints',
        icon: BackupTableTwoToneIcon,
        link: '',
        items: [
          {
            name: 'Extended sidebar',
            link: `/${firstUrlSegment}/dashboards`,
            badge: 'v3.0',
            badgeTooltip: 'Added in version 3.0'
          },
          {
            name: 'Accent header',
            link: '/accent-header/dashboards',
            badge: '',
            badgeTooltip: 'Updated'
          },
          {
            name: 'Accent sidebar',
            link: '/accent-sidebar/dashboards'
          },
          {
            name: 'Boxed sidebar',
            link: '/boxed-sidebar/dashboards'
          },
          {
            name: 'Collapsed sidebar',
            link: '/collapsed-sidebar/dashboards'
          },
          {
            name: 'Bottom navigation',
            link: '/bottom-navigation/dashboards'
          },
          {
            name: 'Top navigation',
            link: '/top-navigation/dashboards'
          }
        ]
      },
      {
        name: 'Dashboards',
        icon: SmartToyTwoToneIcon,
        link: `/${firstUrlSegment}/dashboards`,
        items: [
          {
            name: 'Reports',
            link: 'dashboards/reports',
            badge: '',
            badgeTooltip: 'Reports Dashboard - version 3.0'
          },
          {
            name: 'Expenses',
            link: 'dashboards/expenses',
            badge: '',
            badgeTooltip: 'Expenses Dashboard - version 3.0'
          },
          {
            name: 'Products',
            link: 'dashboards/products',
            badge: '',
            badgeTooltip: 'Products Dashboard - version 3.0'
          },
          {
            name: 'Statistics',
            link: 'dashboards/statistics',
            badge: '',
            badgeTooltip: 'Statistics Dashboard - version 3.0'
          },
          {
            name: 'Automation',
            link: 'dashboards/automation'
          },
          {
            name: 'Analytics',
            link: 'dashboards/analytics'
          },
          {
            name: 'Banking',
            link: 'dashboards/banking'
          },
          {
            name: 'Commerce',
            link: 'dashboards/commerce'
          },
          {
            name: 'Crypto',
            link: 'dashboards/crypto'
          },
          {
            name: 'Finance',
            link: 'dashboards/finance'
          },
          {
            name: 'Fitness',
            link: 'dashboards/fitness'
          },
          {
            name: 'Healthcare',
            link: `/${firstUrlSegment}/dashboards/healthcare`,
            items: [
              {
                name: 'Doctors',
                link: 'dashboards/healthcare/doctor'
              },
              {
                name: 'Hospital',
                link: 'dashboards/healthcare/hospital'
              }
            ]
          },
          {
            name: 'Helpdesk',
            link: 'dashboards/helpdesk'
          },
          {
            name: 'Learning',
            link: 'dashboards/learning'
          },
          {
            name: 'Monitoring',
            link: 'dashboards/monitoring'
          },
          {
            name: 'Tasks',
            link: 'dashboards/tasks'
          }
        ]
      },
      {
        name: 'Data Display',
        icon: HealthAndSafetyTwoToneIcon,
        badge: '',
        badgeTooltip: 'Ekki 3.0 contains over 250 new data display blocks',
        link: `/${firstUrlSegment}/blocks`,
        items: [
          {
            name: 'Charts large',
            link: 'blocks/charts-large'
          },
          {
            name: 'Charts small',
            link: 'blocks/charts-small'
          },
          {
            name: 'Composed cards',
            link: 'blocks/composed-cards'
          },
          {
            name: 'Grids',
            link: 'blocks/grids'
          },
          {
            name: 'Icon cards',
            link: 'blocks/icon-cards'
          },
          {
            name: 'Image cards',
            link: 'blocks/image-cards'
          },
          {
            name: 'Lists large',
            link: 'blocks/lists-large'
          },
          {
            name: 'Lists small',
            link: 'blocks/lists-small'
          },
          {
            name: 'Navigation',
            link: 'blocks/navigation'
          },
          {
            name: 'Profile cards',
            link: 'blocks/profile-cards'
          },
          {
            name: 'Progress circular',
            link: 'blocks/progress-circular'
          },
          {
            name: 'Progress horizontal',
            link: 'blocks/progress-horizontal'
          },
          {
            name: 'Sparklines large',
            link: 'blocks/sparklines-large'
          },
          {
            name: 'Sparklines small',
            link: 'blocks/sparklines-small'
          },
          {
            name: 'Statistics',
            link: 'blocks/statistics'
          }
        ]
      },
      {
        name: 'Applications',
        icon: AnalyticsTwoToneIcon,
        link: `/${firstUrlSegment}/applications`,
        items: [
          {
            name: 'Calendar',
            link: 'applications/calendar'
          },
          {
            name: 'File Manager',
            link: 'applications/file-manager'
          },
          {
            name: 'Jobs Platform',
            link: 'applications/jobs-platform'
          },
          {
            name: 'Mailbox',
            link: 'management/mailbox/inbox'
          },
          {
            name: 'Messenger',
            link: 'applications/messenger'
          },
          {
            name: 'Projects Board',
            link: 'applications/projects-board'
          }
        ]
      }
    ]
  },
  {
    heading: 'Management',
    items: [
      {
        name: 'Users',
        icon: AssignmentIndTwoToneIcon,
        link: `/${firstUrlSegment}/management/users`,
        items: [
          {
            name: 'List',
            link: 'management/users/list'
          },
          {
            name: 'User Profile',
            link: 'management/users/single'
          }
        ]
      },
      {
        name: 'Projects',
        icon: AccountTreeTwoToneIcon,
        link: `/${firstUrlSegment}/management/projects/list`
      },
      {
        name: 'Commerce',
        icon: StorefrontTwoToneIcon,
        link: `/${firstUrlSegment}/management/commerce`,
        items: [
          {
            name: 'Shop',
            link: 'management/commerce/shop'
          },
          {
            name: 'List',
            link: 'management/commerce/products/list'
          },
          {
            name: 'Details',
            link: 'management/commerce/products/single/1'
          },
          {
            name: 'Create',
            link: 'management/commerce/products/create'
          }
        ]
      },
      {
        name: 'Invoices',
        icon: ReceiptTwoToneIcon,
        link: `/${firstUrlSegment}/management/invoices`,
        items: [
          {
            name: 'List',
            link: 'management/invoices/list'
          },
          {
            name: 'Details',
            link: 'management/invoices/single'
          }
        ]
      }
    ]
  },
  {
    heading: 'Extra Pages',
    items: [
      {
        name: 'Auth Pages',
        icon: VpnKeyTwoToneIcon,
        link: '/auth',
        items: [
          {
            name: 'Login',
            items: [
              {
                name: 'Basic',
                link: '/account/login-basic'
              },
              {
                name: 'Cover',
                link: '/account/login-cover'
              }
            ]
          },
          {
            name: 'Register',
            items: [
              {
                name: 'Basic',
                link: '/account/register-basic'
              },
              {
                name: 'Cover',
                link: '/account/register-cover'
              },
              {
                name: 'Wizard',
                link: '/account/register-wizard'
              }
            ]
          },
          {
            name: 'Recover Password',
            link: '/account/recover-password'
          }
        ]
      },
      {
        name: 'Status',
        icon: ErrorTwoToneIcon,
        link: '/status',
        items: [
          {
            name: 'Error 404',
            link: '/status/404'
          },
          {
            name: 'Error 500',
            link: '/status/500'
          },
          {
            name: 'Maintenance',
            link: '/status/maintenance'
          },
          {
            name: 'Coming Soon',
            link: '/status/coming-soon'
          }
        ]
      }
    ]
  },
  {
    heading: 'Foundation',
    items: [
      {
        name: 'Overview',
        link: '/overview',
        icon: DesignServicesTwoToneIcon
      },
      {
        name: 'Documentation',
        icon: SupportTwoToneIcon,
        link: '/docs'
      }
    ]
  }
];

const AllMenuItems = process.env.NODE_ENV === 'development' ? menuItems.concat(otherMenuItems) : menuItems;
export default AllMenuItems;
